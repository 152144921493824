<template>
  <div class="contents register userInfo">
    <section class="box one">
      <h2>문장 관리</h2>

      <div class="inputBox word">
        <div class="flex">
          <p>
            단어
            <span>*</span>
          </p>
          <input type="text" v-model="word" />
        </div>
        <div></div>

        <div class="flex">
          <p>
            카테고리
            <span>*</span>
          </p>
          <el-select v-model="category">
            <el-option
              v-for="(item, i) in categories"
              :key="i"
              :value="item"
              :label="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="flex">
          <p>
            레벨
            <span>*</span>
          </p>
          <el-select v-model="level">
            <el-option
              v-for="(item, i) in levels"
              :key="i"
              :value="item"
              :label="'Level ' + item"
            >
            </el-option>
          </el-select>
        </div>

        <div class="flex">
          <p>
            앞 문장
            <span>*</span>
          </p>
          <input type="text" v-model="phrase_front" />
        </div>
        <div class="flex">
          <p>
            뒷 문장
            <span>*</span>
          </p>
          <input type="text" v-model="phrase_back" />
        </div>

        <div class="flex double">
          <p>
            보기
            <span>*</span>
          </p>
          <div class="flex desc">
            <input class="full" type="text" v-model="example1" />
            <input class="full" type="text" v-model="example2" />
            <input class="full" type="text" v-model="example3" />
          </div>
        </div>

        <div class="flex double">
          <p>
            예문
            <span>*</span>
          </p>
          <div class="flex desc sentence">
            <input
              class="full"
              type="text"
              v-model="description_en"
              placeholder="영어"
            />
            <input
              class="full"
              type="text"
              v-model="description_jp"
              placeholder="일본어"
            />
            <input
              class="full"
              type="text"
              v-model="description_cn"
              placeholder="중국어"
            />
            <input
              class="full"
              type="text"
              v-model="description_es"
              placeholder="스페인어"
            />
          </div>
        </div>
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </section>
    <div class="buttonWrap">
      <router-link to="/admin/sentence/manage">
        <span class="material-icons-round"> keyboard_backspace </span
        >목록</router-link
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import category from "@/config/sentenceCategory.json";
import {
  createSentence,
  fetchSentence,
  updateSentence,
  removeSentence,
} from "@/api/admin";
export default {
  data() {
    return {
      editMode: false,
      moment: moment,
      category: "",
      word: "",
      phrase_front: "",
      phrase_back: "",
      examples: [],
      example1: "",
      example2: "",
      example3: "",
      level: "",
      levels: ["1", "2", "3", "4", "5"],
      description_en: "",
      description_jp: "",
      description_cn: "",
      description_es: "",
      updatedAt: "",
      createdAt: "",
      categories: "",
      modalVisible: false,
    };
  },

  created() {
    this.$store.dispatch("SET_NAVBAR", "3");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.sentenceId = this.$route.query.id;
      this.getSentence();
    }
    this.categories = category.categories;
  },
  methods: {
    getSentence() {
      fetchSentence(this.sentenceId).then((res) => {
        console.log("detail", res.data.data);
        if (res.data.status == 200) {
          this.category = res.data.data.category;
          this.word = res.data.data.word;
          this.phrase_front = res.data.data.phrase_front;
          this.phrase_back = res.data.data.phrase_back;
          this.word_es = res.data.data.word_es;
          this.description_en = res.data.data.description_en;
          this.description_cn = res.data.data.description_cn;
          this.description_jp = res.data.data.description_jp;
          this.description_es = res.data.data.description_es;
          this.level = res.data.data.level;
          this.example1 = res.data.data.examples[0];
          this.example2 = res.data.data.examples[1];
          this.example3 = res.data.data.examples[2];
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },

    handleDelete() {
      let result = confirm("삭제 하시겠습니까?");
      if (!result) {
        return;
      }
      removeSentence(this.sentenceId).then((res) => {
        if (res.data.status == 200) {
          alert("단어 정보가 삭제 되었습니다.");
          this.$router.replace("/admin/sentence/manage");
        }
      });
    },
    submit() {
      if (this.category == "") {
        return alert("카테고리를 선택해 주세요.");
      } else if (this.word == "") {
        return alert("단어를 입력해 주세요.");
      } else if (this.level == "") {
        return alert("레벨을 입력해 주세요.");
      } else if (this.phrase_back == "") {
        return alert("앞문장을 입력해 주세요.");
      } else if (this.phrase_front == "") {
        return alert("뒷문장을 입력해 주세요.");
      } else if (
        this.example1 == "" ||
        this.example2 == "" ||
        this.example3 == ""
      ) {
        return alert("보기를 입력해 주세요.");
      }
      this.examples.push(this.example1);
      this.examples.push(this.example2);
      this.examples.push(this.example3);

      let data = {
        sentenceId: this.sentenceId,
        category: this.category,
        word: this.word,
        phrase_front: this.phrase_front,
        phrase_back: this.phrase_back,
        level: this.level,
        description_en: this.description_en,
        description_jp: this.description_jp,
        description_cn: this.description_cn,
        description_es: this.description_es,
        examples: this.examples,
      };

      if (this.editMode) {
        updateSentence(data).then((res) => {
          console.log(data.examples);
          if (res.data.status == 200) {
            alert("문장 정보가 반영 되었습니다.");
            this.$router.push("/admin/sentence/manage");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      } else {
        createSentence(data).then((res) => {
          console.log(data);
          if (res.data.status == 200) {
            alert("문장 등록이 완료 되었습니다.");
            this.$router.push("/admin/sentence/manage");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      }
    },
  },
};
</script>
<style>
textarea {
  width: 100% !important;
}
</style>
